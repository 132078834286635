<mat-card class="container" *ngIf="cut">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{cut.name}}</mat-card-title>
      <mat-card-subtitle>{{cut.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <cut-chart #graph
               (slopeDragging)="onSlopeDragging($event)"
               (slopeHasChanged)="onSlopeHasChanged($event)">
    </cut-chart>

    <div class="mesures">
      <div *ngIf="horizontalDistance">
        <div class="module-title">{{'horizontal_distance_from_slope' | transloco | uppercase}} :</div>
        <div class="indicators">{{ horizontalDistance.toFixed(2) }} m</div>
      </div>
      <div *ngIf="verticalDistance">
        <div class="module-title">{{'vertical_distance_from_slope' | transloco | uppercase}} :</div>
        <div class="indicators">{{ verticalDistance.toFixed(2) }} m</div>
      </div>
      <div *ngIf="angle">
        <div class="module-title">{{'slope_angle' | transloco | uppercase}} :</div>
        <div class="indicators">{{ angle.toFixed(2) }}°</div>
      </div>
    </div>

    <div class="comment">
      <textarea placeholder="{{'report_note' | transloco}}"
                #comment
                (focus)="onHandleFocus()"
                (focusout)="onFocusLeave()">{{cut.comment}}</textarea>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <div class="left-actions">
      <button mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              (click)="onClickDelete(cut)">delete
      </button>
      <div class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'export' | transloco}}"
              [matMenuTriggerFor]="menu">ios_share
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickExportGraph(cut)">{{'export_graph' | transloco}}</button>
        <button mat-menu-item (click)="onClickExportData(cut)">{{'export_data' | transloco}}</button>
        <button mat-menu-item (click)="onClickExportDXF(cut)">{{'export_dxf' | transloco}}</button>
      </mat-menu>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_cut' | transloco}}"
              (click)="onClickFlyTo(cut)">location_on
      </button>
      <button mat-icon-button
              matTooltip="{{'rename' | transloco}}"
              (click)="onClickRename(cut)"><mat-icon>edit</mat-icon></button>
    </div>
  </mat-card-actions>
</mat-card>
