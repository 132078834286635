<mat-card class="container" *ngIf="measure">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{measure.name}}</mat-card-title>
      <mat-card-subtitle *ngIf="measure.straightMeasure">{{'straight_line_distance' | transloco}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="measure.areaVolume">{{'area_and_volume' | transloco}}</mat-card-subtitle>
      <mat-card-subtitle>{{measure.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div *ngIf="measure.straightMeasure">
      <div>
        <div class="module-title">{{'direct_distance' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.straightMeasure.directDistance.toFixed(2)}} m</div>
      </div>
      <div>
        <div class="module-title">{{'distance_follwing_the_terrain_from_above' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.straightMeasure.topTerrainDistance.toFixed(2)}} m</div>
      </div>
      <div>
        <div class="module-title">{{'distance_follwing_the_ground_from_the_front' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.straightMeasure.faceTerrainDistance.toFixed(2)}} m</div>
      </div>
    </div>
    <div *ngIf="measure.areaVolume">
      <div>
        <div class="module-title">{{'projected_area' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.areaVolume.projectedArea.toFixed(2)}} m2</div>
      </div>
      <div>
        <div class="module-title">{{'estimate_of_actual_area' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.areaVolume.terrainArea.toFixed(2)}} m2</div>
      </div>
      <div>
        <div class="module-title">{{'full_volume_above_the_average_plan' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.areaVolume.upFullVolume.toFixed(2)}} m3</div>
      </div>
      <div>
        <div class="module-title">{{'full_volume_below_the_average_plan' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.areaVolume.downFullVolume.toFixed(2)}} m3</div>
      </div>
      <div>
        <div class="module-title">{{'empty_volume_below_average_plan' | transloco | uppercase}} :</div>
        <div class="indicators">{{ measure.areaVolume.downEmptyVolume.toFixed(2)}} m3</div>
      </div>
    </div>
    <div class="comment">
      <textarea placeholder="{{'report_note' | transloco}}"
                #comment
                (focus)="onHandleFocus()"
                (focusout)="onFocusLeave()">{{measure.comment}}</textarea>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <div class="left-actions">
      <button mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              class="material-symbols-outlined delete-btn"
              (click)="onClickDelete(measure)">delete
      </button>
      <div class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'export' | transloco}}"
              [matMenuTriggerFor]="menu">ios_share
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickExportData(measure)">{{'export_data' | transloco}}</button>
      </mat-menu>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_measure' | transloco}}"
              (click)="onClickFlyTo(measure)">location_on
      </button>
      <button mat-icon-button
              matTooltip="{{'rename' | transloco}}"
              (click)="onClickRename(measure)"><mat-icon>edit</mat-icon></button>
    </div>
  </mat-card-actions>
</mat-card>
